import Choices from 'choices.js';
import { addAlert } from './alerts';

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1),
                );
                break;
            }
        }
    }
    return cookieValue;
}

export function debounce(func, timeout = 300) {
    /*
      // Example usage:
      const setFilters = debounce((e) => {
        table.setFilter(e.target.name, "=", e.target.value);
      }, 300);
      input.addEventListener("change", function (e) {
        setFilters(e);
      });
  */
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function toggleAreaExpanded(selector) {
    let element;
    if (typeof selector === 'string') {
        element = document.querySelector(selector);
    } else if (typeof selector === 'object') {
        element = selector;
    }
    !(element.getAttribute('aria-expanded') == 'true')
        ? element.setAttribute('aria-expanded', 'true')
        : element.setAttribute('aria-expanded', 'false');
}

export function handleMobileMenu() {
    const menu_btn = document.getElementById('mobile-menu-btn');
    const menu = document.getElementById('main-nav');

    menu_btn.addEventListener('click', (e) => {
        e.preventDefault();
        toggleAreaExpanded(menu_btn);
        menu.classList.toggle('opened');
    });

    hideOnClickOutside(menu, menu_btn);
}

export const isVisible = (elem) =>
    !!elem &&
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

const isActive = (element, parent) => {
    return (
        element.classList.contains('opened') ||
        element.classList.contains('active') ||
        parent.classList.contains('opened') ||
        parent.classList.contains('active')
    );
};

export function hideOnClickOutside(element, parent) {
    const outsideClickListener = (event) => {
        if (
            !parent.contains(event.target) &&
            !element.contains(event.target) &&
            !isVisible(document.querySelector('.flatpickr-calendar')) &&
            isVisible(element)
        ) {
            if (
                isActive(element, parent) &&
                parent.querySelector('[aria-expanded]')
            ) {
                toggleAreaExpanded(parent.querySelector('[aria-expanded]'));
            }
            if (
                isActive(element, parent) &&
                parent.hasAttribute('aria-expanded')
            ) {
                toggleAreaExpanded(parent);
            }
            element.classList.remove('opened');
            element.classList.remove('active');
            parent.classList.remove('opened');
            parent.classList.remove('open');
            parent.classList.remove('active');
        }
    };
    document.addEventListener('click', outsideClickListener);
}

export class SelectInsteadTabs {
    constructor(selector) {
        if (typeof selector === 'string') {
            this.original_panel = document.querySelector(selector);
        } else if (typeof selector === 'object') {
            this.original_panel = selector;
        }
        this._makeControl();
    }

    _makeControl() {
        const select_wrapper = document.createElement('div');
        select_wrapper.classList.add('select-wrapper');
        const main_controller_container = document.createElement('div');
        const collapse_btn = document.createElement('button');
        const text_content = document.createElement('span');
        const caret_icon = document.createElement('span');
        caret_icon.classList.add('icon-arrow_down');
        collapse_btn.appendChild(caret_icon);
        main_controller_container.classList.add('select-tab-controller');
        main_controller_container.appendChild(text_content);
        main_controller_container.querySelector('span').innerText =
            this._getActiveTabName();
        main_controller_container.appendChild(collapse_btn);
        select_wrapper.appendChild(main_controller_container);
        const new_list = this._makeList();
        select_wrapper.appendChild(new_list);
        this.original_panel.parentNode.insertBefore(
            select_wrapper,
            this.original_panel.parentNode.firstChild,
        );

        collapse_btn.addEventListener('click', (e) => {
            e.preventDefault();
            main_controller_container.classList.toggle('open');
        });

        new_list.querySelectorAll('li').forEach((elem) => {
            elem.addEventListener('click', (e) => {
                const original_object_to_active = [
                    ...this._getOriginalActiveObjects(),
                ].filter((obj) => {
                    return elem.innerText == obj.innerText;
                })[0];
                main_controller_container.querySelector('span').innerText =
                    elem.innerText;
                this._clearActiveClasses(new_list);
                elem.classList.add('active');
                elem.querySelector('button').setAttribute(
                    'aria-selected',
                    'true',
                );
                original_object_to_active.dispatchEvent(new Event('click'));
                collapse_btn.dispatchEvent(new Event('click'));
            });
        });

        hideOnClickOutside(
            main_controller_container,
            main_controller_container,
        );
    }

    _clearActiveClasses(ul) {
        ul.querySelectorAll('li').forEach((li) => {
            li.querySelector('button').setAttribute('aria-selected', 'false');
            li.classList.remove('active');
        });
    }

    _getActiveTabName() {
        return this.original_panel.querySelector('.active').textContent.trim();
    }

    _getOriginalActiveObjects(original_active_objects = 'button') {
        return this.original_panel.querySelectorAll(original_active_objects);
    }

    _makeList() {
        const original_nav_links = this._getOriginalActiveObjects();
        const new_list = document.createElement('ul');
        new_list.classList.add('mobile_tab_list', 'nav');
        new_list.setAttribute('role', 'tablist');
        const elems_of_list = [];
        original_nav_links.forEach((el) => {
            if (el.classList.contains('active')) {
                elems_of_list.push(
                    `<li class="active"><button role="tab">${el.innerText}</button></li>`,
                );
            } else {
                elems_of_list.push(
                    `<li><button role="tab">${el.innerText}</button></li>`,
                );
            }
        });
        new_list.innerHTML = elems_of_list.join('');
        return new_list;
    }
}

export async function sendDeleteRequest(url) {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}
export async function sendPostRequest(url, data) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}

export function textInterpolation(template, ...expressions) {
    return template.reduce((accumulator, part, i) => {
        return accumulator + expressions[i - 1] + part;
    });
}

const ACTIONS = ['INC', 'DEC'];

export function operateOnInput(target, action) {
    const input_id = target
        .closest('.spinner-container')
        .getAttribute('data-input');
    const input = document.getElementById(input_id);
    const min = parseFloat(input.getAttribute('min')) || null;
    const max = parseFloat(input.getAttribute('max')) || null;
    if (action == ACTIONS[0]) {
        input.value < max || max == null ? input.stepUp() : '';
    } else {
        input.value > min || min == null ? input.stepDown() : '';
    }
    input.dispatchEvent(new Event('change'));
}

export function initializeNumberSpinner(parent_element = document) {
    /* CUSTOM SPINERS FOR INPUT TYPE NUMBER */
    const spinner_template = `
    <div class="spinner-container">
        <div class="up"><span class="icon-arrow_up"></span></div>
        <div class="down"><span class="icon-arrow_down"></span></div>
    </div>
    `;
    const number_inputs = parent_element.querySelectorAll(
        "fieldset > input[type='number']",
    );
    number_inputs.forEach((el) => {
        const parent = el.closest('fieldset');
        if (
            !parent.querySelector('input').disabled &&
            !parent.querySelector('.spinner-container')
        ) {
            el.insertAdjacentHTML('afterend', spinner_template);
            parent
                .querySelector('.spinner-container')
                .setAttribute('data-input', el.id);
        }
    });

    parent_element.querySelectorAll('.spinner-container .up').forEach((el) => {
        el.addEventListener('click', function (e) {
            operateOnInput(e.target, ACTIONS[0]);
        });
    });

    parent_element
        .querySelectorAll('.spinner-container .down')
        .forEach((el) => {
            el.addEventListener('click', function (e) {
                operateOnInput(e.target, ACTIONS[1]);
            });
        });
}

export function initializeSelect(rootElement) {
    let element;
    if (typeof rootElement === 'string') {
        element = document.querySelector(selector);
    } else if (typeof rootElement === 'object') {
        element = rootElement;
    }
    const choice = new Choices(element, {
        shouldSort: false,
        callbackOnInit: function () {
            element.style.display = 'block';
            element.classList.add('u-sr-only');
            element.setAttribute('aria-hidden', true);
        },
    });
    element.addEventListener('clean', (e) => {
        choice.setChoiceByValue('');
    });
}

export function initializeAddressAutoFill(user_id) {
    const [
        address_select,
        voivodeship_field,
        county_field,
        community_field,
        city_field,
        street_field,
        building_number_field,
        local_number_field,
        postal_code_field,
        save_address_checkbox,
    ] = [
        document.getElementById('id_address'),
        document.getElementById('id_voivodeship'),
        document.getElementById('id_county'),
        document.getElementById('id_community'),
        document.getElementById('id_city'),
        document.getElementById('id_street'),
        document.getElementById('id_building_number'),
        document.getElementById('id_local_number'),
        document.getElementById('id_postal_code'),
        document.getElementById('id_save_address'),
    ];
    async function clean_data() {
        voivodeship_field.value = '';
        county_field.value = '';
        community_field.value = '';
        city_field.value = '';
        street_field.value = '';
        building_number_field.value = '';
        local_number_field.value = '';
        postal_code_field.value = '';
        save_address_checkbox.checked = false;
    }
    async function set_data(data) {
        voivodeship_field.value = data.voivodeship;
        county_field.value = data.county;
        community_field.value = data.community;
        city_field.value = data.city;
        street_field.value = data.street;
        building_number_field.value = data.building_number;
        local_number_field.value = data.local_number;
        postal_code_field.value = data.postal_code;
        save_address_checkbox.checked = false;
    }
    address_select.addEventListener('change', async (e) => {
        if (e.detail.value) {
            const response = await fetch(
                `/api/users/${user_id}/address/${e.detail.value}`,
            );
            if (response.ok) {
                const data = await response.json();
                set_data(data);
            } else {
                clean_data();
            }
        } else {
            clean_data();
        }
    });
}

export async function interceptError(event) {
    const parentField = event.target.closest('.form-field');
    if (parentField.querySelectorAll('div.error-title').length === 0) {
        const div = document.createElement('div');
        div.textContent = `${event.target.validationMessage}`;
        div.classList.add('error-title');
        parentField.appendChild(div);
    }
    parentField.classList.add('with-error');
    event.preventDefault();
}

export async function checkValidation(field) {
    const fieldFormfield = field.closest('.form-field');
    const errors = fieldFormfield.querySelectorAll('div.error-title');
    const validity = field.checkValidity();
    if (errors.length > 0 && validity) {
        errors.forEach((error) => {
            fieldFormfield.removeChild(error);
        });
        fieldFormfield.classList.remove('with-error');
    }
    field.reportValidity();
    return validity;
}

export async function checkChangeError(event) {
    checkValidation(event.target);
}

export function isRequiredFulfilled(form) {
    const requiredFields = Array.from(
        form.querySelectorAll('[required]'),
    ).filter((field) => field.type !== 'radio');
    const requiredRadioButtons = Array.from(
        form.querySelectorAll('input[type="radio"][required]'),
    );
    const submitButton =
        form.parentElement.parentElement.querySelector('[type="submit"]');
    let allRequiredFulfilled = true;

    const groupedRadioButtons = requiredRadioButtons.reduce((acc, button) => {
        const prefix = button.id.match(/^(\D*)/)[0];
        if (!acc[prefix]) acc[prefix] = [];
        acc[prefix].push(button);
        return acc;
    }, {});

    requiredFields.forEach((field) => {
        if (field.nodeName === 'INPUT' && field.value === '') {
            allRequiredFulfilled = false;
        } else if (field.nodeName === 'SELECT') {
            let anySelected = false;
            for (const option of field.options) {
                if (option.value !== '') {
                    anySelected = true;
                }
            }
            if (!anySelected) {
                allRequiredFulfilled = false;
            }
        }
    });
    Object.entries(groupedRadioButtons).forEach(([prefix, buttons]) => {
        let anySelected = false;
        buttons.forEach((button) => {
            if (button.checked) {
                anySelected = true;
            }
        });
        if (!anySelected) {
            allRequiredFulfilled = false;
        }
    });
    submitButton.disabled = !allRequiredFulfilled;
}

export async function isRequiredFulfilledListener(event) {
    checkValidation(event.target);
    isRequiredFulfilled(event.target.form);
}

export function initializeAddressSave(target) {
    target
        .querySelector('.btn-submit')
        .addEventListener('click', async function (e) {
            e.preventDefault();
            let hasError = false;
            let errorForm = null;
            const form = this.form;

            const formData = new FormData(form);
            try {
                const response = await fetch(form.action, {
                    method: 'post',
                    body: formData,
                    headers: {
                        ajax: true,
                    },
                });
                const htmlForm = await response.text();
                form.innerHTML = htmlForm;
                initializeAddressSave(target);
                if (response.status !== 200) {
                    hasError = true;
                    errorForm = form;
                }
            } catch {
                hasError = true;
                errorForm = form;
            }
            if (!hasError) {
                addAlert(
                    'success',
                    gettext('Adres został poprawnie edytowany.'),
                );
            } else {
                addAlert(
                    'error',
                    gettext('Wystąpił błąd podczas zapisania zmian.'),
                );
                if (errorForm) {
                    errorForm.scrollIntoView();
                }
            }
        });
}

export default {
    getCookie,
    debounce,
    toggleAreaExpanded,
    handleMobileMenu,
    hideOnClickOutside,
    SelectInsteadTabs,
    sendDeleteRequest,
    sendPostRequest,
    textInterpolation,
    initializeNumberSpinner,
    initializeSelect,
    initializeAddressAutoFill,
    operateOnInput,
    interceptError,
    checkChangeError,
    isRequiredFulfilled,
    isRequiredFulfilledListener,
    initializeAddressSave,
};
