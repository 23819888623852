import flatpickr from 'flatpickr';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localePl from 'air-datepicker/locale/pl';
import { isVisible } from './utils';

const pickers = {};

export function initializeDateRangePicker(element) {
    pickers[element.id] = flatpickr(element, {
        mode: 'range',
        onClose: function (selectedDates, dateStr, instance) {
            if (selectedDates.length === 1 && !instance.isSingleDate) {
                instance.isSingleDate = true;
                instance.setDate([selectedDates[0], selectedDates[0]], true);
                instance.isSingleDate = false;
            }
        },
        onChange: function (selectedDates, dateStr, instance) {
            if (
                selectedDates[1] &&
                new Date(selectedDates[0]).setHours(0, 0, 0, 0) !==
                    new Date(selectedDates[1]).setHours(0, 0, 0, 0)
            ) {
                dateStr = dateStr.replace('to', 'do:');
                instance.element.value = `od: ${dateStr}`;
            } else {
                instance.element.value = dateStr;
            }
        },
    });
}

export function initializeDateTimeFields(element) {
    element.querySelectorAll('.daterangeinput').forEach((element) => {
        const wrapper = document.createElement('div');
        wrapper.classList.add('input-with-date-icon');
        element.parentNode.insertBefore(wrapper, element);
        wrapper.appendChild(element);
        initializeDateRangePicker(element);
    });
}

document.addEventListener('DOMContentLoaded', async function () {
    initializeDateTimeFields(document);
});

function convertDefaultDatepickerWidget(id, dp = null, icon = 'icon-calendar') {
    const date_input = document.querySelector(id);
    const parent = date_input.parentElement;
    const wrapper = document.createElement('DIV');
    const button = document.createElement('BUTTON');
    button.innerHTML = `<span class="${icon}"></span>`;
    wrapper.classList.add('custom-datepicker');
    wrapper.appendChild(date_input);
    wrapper.appendChild(button);
    parent.appendChild(wrapper);

    button.addEventListener('click', (e) => {
        e.preventDefault();
        if (dp) {
            if (!dp.visible) {
                dp.show();
            } else {
                dp.hide();
            }
        }
    });
}

export function initializeCustomDatepicker(
    elem,
    options = { locale: localePl, showEvent: 'click' },
) {
    const dp = new AirDatepicker(elem, options);
    convertDefaultDatepickerWidget(elem, dp);

    function hideOnClickOutside(dp, elem) {
        const outsideCalendarClickListener = (event) => {
            const input = document.querySelector(elem);
            const button = input.nextSibling;
            if (
                !input.contains(event.target) &&
                !button.contains(event.target) &&
                !dp.$datepicker.contains(event.target) &&
                isVisible(dp.$datepicker)
            ) {
                dp.hide();
            }
        };
        document.addEventListener('click', outsideCalendarClickListener);
    }
    hideOnClickOutside(dp, elem);
}

export function initializeCustomTimepicker(elem) {
    convertDefaultDatepickerWidget(elem, null, 'icon-clock');
    createCustomTimepicker('id_waste_collection_time');
}

function createCustomTimepicker(input_id) {
    const input = document.getElementById(input_id);
    const container = input.closest('.custom-datepicker');
    const btn = input.nextSibling;
    let timer_hour_up, timer_hour_down, timer_minute_up, timer_minute_down;

    const widget = createWidget(container);
    hideOnClickOutside(widget.container, container);

    function hideOnClickOutside(element, parent) {
        const outsideClickListener = (event) => {
            if (
                !parent.contains(event.target) &&
                !element.contains(event.target) &&
                isVisible(element)
            ) {
                element.classList.add('hidden');
            }
        };
        document.addEventListener('click', outsideClickListener);
    }

    function increaseHourByOne() {
        const current_value = parseInt(widget.hour_input.value);
        if (!isNaN(current_value)) {
            const new_value = (current_value + 1) % 24;
            if (new_value < 10) {
                widget.hour_input.value = '0' + new_value;
            } else {
                widget.hour_input.value = new_value;
            }
        } else {
            widget.hour_input.value = '00';
        }
    }

    function increaseMinuteByOne() {
        const current_value = parseInt(widget.minute_input.value);
        if (!isNaN(current_value)) {
            const new_value = (current_value + 1) % 60;
            if (new_value < 10) {
                widget.minute_input.value = '0' + new_value;
            } else {
                widget.minute_input.value = new_value;
            }
        } else {
            widget.minute_input.value = '00';
        }
    }

    function decreaseHourByOne() {
        const current_value = parseInt(widget.hour_input.value);
        if (!isNaN(current_value)) {
            let new_value = (current_value - 1) % 24;
            new_value < 0 ? (new_value = 23) : null;
            if (new_value < 10) {
                widget.hour_input.value = '0' + new_value;
            } else {
                widget.hour_input.value = new_value;
            }
        } else {
            widget.hour_input.value = '00';
        }
    }

    function decreaseMinuteByOne() {
        const current_value = parseInt(widget.minute_input.value);
        if (!isNaN(current_value)) {
            let new_value = (current_value - 1) % 60;
            new_value < 0 ? (new_value = 59) : null;
            if (new_value < 10) {
                widget.minute_input.value = '0' + new_value;
            } else {
                widget.minute_input.value = new_value;
            }
        } else {
            widget.minute_input.value = '00';
        }
    }

    function initializeValues(timepicker_container, hour_input, minute_input) {
        const main_input = timepicker_container.querySelector('input');
        if (main_input.value) {
            const [hour, minute] = main_input.value.split(':');
            hour_input.value = hour;
            minute_input.value = minute;
        } else {
            hour_input.value = '00';
            minute_input.value = '00';
        }
    }

    function createWidget(timepicker_container) {
        const container = document.createElement('DIV');
        const widget_container = document.createElement('DIV');
        const hour_container = document.createElement('DIV');
        const minute_container = document.createElement('DIV');
        const hour_inc = document.createElement('BUTTON');
        const hour_dec = document.createElement('BUTTON');
        const minute_inc = document.createElement('BUTTON');
        const minute_dec = document.createElement('BUTTON');
        const hour_input = document.createElement('INPUT');
        const minute_input = document.createElement('INPUT');
        const confirm_btn = document.createElement('BUTTON');
        const input_separator = document.createElement('SPAN');

        hour_container.appendChild(hour_inc);
        hour_container.appendChild(hour_input);
        hour_container.appendChild(hour_dec);
        minute_container.appendChild(minute_inc);
        minute_container.appendChild(minute_input);
        minute_container.appendChild(minute_dec);
        widget_container.appendChild(hour_container);
        widget_container.appendChild(input_separator);
        widget_container.appendChild(minute_container);
        container.appendChild(widget_container);
        container.appendChild(confirm_btn);
        timepicker_container.appendChild(container);

        hour_input.type = 'text';
        hour_input.name = 'hour';

        minute_input.type = 'text';
        minute_input.name = 'minute';
        initializeValues(timepicker_container, hour_input, minute_input);

        hour_inc.innerHTML = '<span class="icon-arrow_up"></span>';
        minute_inc.innerHTML = '<span class="icon-arrow_up"></span>';
        hour_dec.innerHTML = '<span class="icon-arrow_down"></span>';
        minute_dec.innerHTML = '<span class="icon-arrow_down"></span>';
        input_separator.textContent = ':';

        confirm_btn.textContent = 'OK';

        container.classList.add('timepicker-widget', 'hidden');
        widget_container.classList.add('widget-container');
        hour_container.classList.add('data-controller');
        minute_container.classList.add('data-controller');
        hour_inc.classList.add('inc');
        minute_inc.classList.add('inc');
        hour_dec.classList.add('dec');
        minute_dec.classList.add('dec');
        confirm_btn.classList.add('confirm-btn');
        input_separator.classList.add('input-separator');

        return {
            container,
            hour_input,
            minute_input,
            hour_inc,
            hour_dec,
            minute_inc,
            minute_dec,
            confirm_btn,
        };
    }

    widget.confirm_btn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        input.value = widget.hour_input.value + ':' + widget.minute_input.value;
        !widget.container.classList.contains('hidden')
            ? widget.container.classList.add('hidden')
            : null;
    });

    btn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        widget.container.classList.toggle('hidden');
    });

    container.addEventListener('click', (e) => {
        e.preventDefault();
        widget.container.classList.contains('hidden')
            ? widget.container.classList.remove('hidden')
            : null;
    });

    widget.hour_inc.addEventListener('click', (e) => {
        e.preventDefault();
    });

    widget.hour_inc.addEventListener('mouseup', (e) => {
        e.preventDefault();
        clearInterval(timer_hour_up);
    });

    widget.hour_inc.addEventListener('mousedown', (e) => {
        e.preventDefault();
        increaseHourByOne();
        timer_hour_up = setInterval(() => {
            increaseHourByOne();
        }, 150);
    });

    widget.hour_dec.addEventListener('click', (e) => {
        e.preventDefault();
    });

    widget.hour_dec.addEventListener('mousedown', (e) => {
        e.preventDefault();
        decreaseHourByOne();
        timer_hour_down = setInterval(() => {
            decreaseHourByOne();
        }, 150);
    });

    widget.hour_dec.addEventListener('mouseup', (e) => {
        e.preventDefault();
        clearInterval(timer_hour_down);
    });

    widget.minute_inc.addEventListener('click', (e) => {
        e.preventDefault();
    });

    widget.minute_inc.addEventListener('mousedown', (e) => {
        e.preventDefault();
        increaseMinuteByOne();
        timer_minute_up = setInterval(() => {
            increaseMinuteByOne();
        }, 150);
    });

    widget.minute_inc.addEventListener('mouseup', (e) => {
        e.preventDefault();
        clearInterval(timer_minute_up);
    });

    widget.minute_dec.addEventListener('click', (e) => {
        e.preventDefault();
    });

    widget.minute_dec.addEventListener('mousedown', (e) => {
        e.preventDefault();
        decreaseMinuteByOne();
        timer_minute_down = setInterval(() => {
            decreaseMinuteByOne();
        }, 150);
    });

    widget.minute_dec.addEventListener('mouseup', (e) => {
        e.preventDefault();
        clearInterval(timer_minute_down);
    });

    widget.hour_inc.addEventListener('mouseout', () => {
        clearInterval(timer_hour_up);
    });

    widget.hour_dec.addEventListener('mouseout', () => {
        clearInterval(timer_hour_down);
    });
    widget.minute_inc.addEventListener('mouseout', () => {
        clearInterval(timer_minute_up);
    });

    widget.minute_dec.addEventListener('mouseout', () => {
        clearInterval(timer_minute_down);
    });
}

export default {
    initializeDateTimeFields,
    initializeCustomDatepicker,
    initializeCustomTimepicker,
};
