import { addAlert } from '../../alerts';
import { deletePrompt } from '../../prompts';

export default function (cell, formatterParams, onRendered) {
    let url = formatterParams.url(cell);
    const str_id = `allert_${cell.getRow().getData().id}`;
    document.addEventListener(
        'click',
        async function (e) {
            if (e.target.closest('.trigger-button') && e.target.id === str_id) {
                e.stopPropagation();

                deletePrompt({
                    options: {
                        title: gettext('Potwierdzenie'),
                        html: `${gettext('Czy na pewno chcesz usunąć ten wniosek?')}
                   <small>${gettext('Proces jest nieodwracalny!')}</small>`,
                        confirmText: gettext('Tak'),
                    },
                    url: url,
                    successAction: function (response) {
                        cell.getTable().setData();
                    },
                });
            }
        },
        true,
    );
    return `<button id="${str_id}" class="btn-link delete trigger-button" ${formatterParams.disabledCondition(cell) ? 'disabled' : ''}>${formatterParams.text}</button>`;
}
