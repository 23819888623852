import { TabulatorFull as Tabulator, PageModule } from 'tabulator-tables';
import { debounce, toggleAreaExpanded } from '../utils';
import RowContextMenuButtonColumn from './formatters/RowContextMenuButtonColumn';

export function initializeTable({
    tableElement = '',
    filtersElement = '',
    ajaxURL = '',
    ajaxParams = '',
    columns = [],
    rowContextMenu = null,
    height = '600px',
    maxHeight = '90vh',
    rowFormatter = null,
    rowClickPopup = undefined,
    initialFilter = [],
    initialSort = [],
    layout = 'fitColumns',
} = {}) {
    if (rowContextMenu && Array.isArray(rowContextMenu)) {
        rowContextMenu = rowContextMenu.filter((item) => item);
    }

    const table = new Tabulator(tableElement, {
        ajaxURL: ajaxURL,
        ajaxParams: ajaxParams,
        ajaxURLGenerator: function (url, config, params) {
            params['limit'] = params.size;
            params['offset'] = (params.page - 1) * params.size;
            const urlParams = {
                limit: params.size,
                offset: (params.page - 1) * params.size,
            };
            for (const [key, value] of Object.entries(params.tabs)) {
                urlParams[key] = value;
            }
            if (params.sort.length > 0) {
                const order =
                    params.sort[0].dir === 'asc'
                        ? params.sort[0].field
                        : `-${params.sort[0].field}`;
                urlParams['order'] = order.replaceAll('.', '__');
            }
            if (params.filter.length > 0) {
                params.filter.forEach((f) => {
                    if (f.value.start || f.value.end) {
                        if (f.value.start) {
                            urlParams[`${f.field.replaceAll('.', '__')}__gte`] =
                                f.value.start;
                        }
                        if (f.value.end) {
                            urlParams[`${f.field.replaceAll('.', '__')}__lte`] =
                                f.value.end;
                        }
                    } else {
                        urlParams[f.field.replaceAll('.', '__')] = f.value;
                    }
                });
            }
            const urlParamsString = new URLSearchParams(urlParams).toString();
            this.externalEvents.dispatch(
                'ajaxURLGenerated',
                url,
                urlParamsString,
            );
            return url + '?' + urlParamsString;
        },
        ajaxResponse: function (url, params, response) {
            this.externalEvents.dispatch('ajaxResponseLoaded', response);
            return {
                last_page: Math.ceil(response.count / params.size),
                last_row: response.count,
                data: response.items,
            };
        },
        height: height,
        minHeight: '15vh',
        maxHeight: maxHeight,
        layout: layout,
        progressiveLoad: 'scroll',
        paginationSize: 50,
        filterMode: 'remote',
        initialFilter: initialFilter,
        initialSort: initialSort,
        sortMode: 'remote',
        selectableRows: false,
        columns: columns,
        rowContextMenu: rowContextMenu,
        rowFormatter: rowFormatter,
        rowClickPopup: rowClickPopup,
        placeholder: function () {
            if (this.getFilters().length === 0) {
                return gettext('Brak danych');
            }
            const container = document.createElement('div');
            container.className = 'tabulator-placeholder-contents';
            container.textContent = gettext('Brak pasujących danych');
            const clearButton = document.createElement('button');
            clearButton.className = 'clear-btn';
            clearButton.textContent = gettext('Wyczyść filtry');
            const that = this;
            clearButton.addEventListener('click', function () {
                that.clearFilter();
                filtersElement.querySelectorAll('input').forEach((input) => {
                    if (
                        input.classList.contains('daterangeinput') &&
                        !input.shouldClear
                    ) {
                        input.shouldClear = true;
                        input._flatpickr.clear();
                        input.shouldClear = false;
                    } else {
                        input.value = '';
                    }
                });
                filtersElement.querySelectorAll('select').forEach((input) => {
                    input.value = '';
                    input.dispatchEvent(new CustomEvent('clean'));
                });
                deleteActiveFilters();
            });
            container.appendChild(clearButton);
            return container;
        },
        headerSortElement:
            '<div class="tabulator-custom-sorter"><span class="arrow-up"></span><span class="arrow-down"></span></div>',
    });

    const active_filters = {};
    const deleteActiveFilters = function () {
        for (let key in active_filters) {
            if (active_filters.hasOwnProperty(key)) {
                delete active_filters[key];
            }
        }
        filtersElement.dispatchEvent(new CustomEvent('filter_change'));
    };

    if (filtersElement) {
        const filter_btn = filtersElement.querySelector('.filter-btn');
        const clear_button = document.createElement('button');
        clear_button.innerHTML = `<span class="icon-close-thin"></span> ${gettext('Wyczyść filtry')}`;
        clear_button.classList.add('clear-btn');
        filtersElement.insertBefore(clear_button, filter_btn);

        clear_button.addEventListener('click', (e) => {
            e.preventDefault();
            table.clearFilter();
            filtersElement.querySelectorAll('input').forEach((input) => {
                if (
                    input.classList.contains('daterangeinput') &&
                    !input.shouldClear
                ) {
                    input.shouldClear = true;
                    input._flatpickr.clear();
                    input.shouldClear = false;
                } else {
                    input.value = '';
                }
            });
            filtersElement.querySelectorAll('select').forEach((input) => {
                input.value = '';
                input.dispatchEvent(new CustomEvent('clean'));
            });
            deleteActiveFilters();
        });

        filtersElement.addEventListener('filter_change', (e) => {
            const filter_obj = e.detail || {};
            const key = Object.keys(filter_obj)[0];
            const value = Object.values(filter_obj)[0];
            if (value) {
                active_filters[key] = value;
            } else {
                delete active_filters[key];
            }
            if (Object.keys(active_filters).length) {
                clear_button.style.display = 'flex';
            } else {
                clear_button.style.display = 'none';
            }
        });

        filtersElement.querySelectorAll('input').forEach((input) => {
            const setFilters = debounce((e) => {
                if (input.classList.contains('daterangeinput')) {
                    const values = input.value.split(' do: ');
                    if (values.length > 1) {
                        const table_active_filters = table
                            .getFilters()
                            .filter((obj) => obj.field !== e.target.name);
                        const selectedValue = [
                            values[0].split('od: ')[1],
                            values[1],
                        ];
                        table_active_filters.push(
                            {
                                field: `${e.target.name}__gte`,
                                type: '=',
                                value: selectedValue[0],
                            },
                            {
                                field: `${e.target.name}__lte`,
                                type: '=',
                                value: selectedValue[1],
                            },
                        );
                        table.setFilter(table_active_filters);
                    } else {
                        const table_active_filters = table
                            .getFilters()
                            .filter(
                                (obj) =>
                                    obj.field !== `${e.target.name}__gte` &&
                                    obj.field !== `${e.target.name}__lte`,
                            );
                        table_active_filters.push({
                            field: `${e.target.name}`,
                            type: '=',
                            value: values[0],
                        });
                        table.setFilter(table_active_filters);
                    }
                } else {
                    table.addFilter(e.target.name, '=', e.target.value);
                }
            });

            input.addEventListener('keyup', function (e) {
                setFilters(e);
                const filter_obj = { [e.target.name]: e.target.value };
                const event = new CustomEvent('filter_change', {
                    detail: filter_obj,
                });
                filtersElement.dispatchEvent(event);
            });
            input.addEventListener('paste', function (e) {
                setFilters(e);
                const filter_obj = { [e.target.name]: e.target.value };
                const event = new CustomEvent('filter_change', {
                    detail: filter_obj,
                });
                filtersElement.dispatchEvent(event);
            });
            if (input._flatpickr) {
                input.addEventListener('change', function (e) {
                    if (input.shouldClear || input._flatpickr.isSingleDate)
                        return;
                    setFilters(e);
                    const filter_obj = { [e.target.name]: e.target.value };
                    const event = new CustomEvent('filter_change', {
                        detail: filter_obj,
                    });
                    filtersElement.dispatchEvent(event);
                });
            }
        });
        filtersElement.querySelectorAll('select').forEach((select) => {
            const setFilters = debounce((e) => {
                if (e.target.value) {
                    table.addFilter(e.target.name, '=', e.target.value);
                } else {
                    const table_active_filters = table.getFilters();
                    table.setFilter(
                        table_active_filters.filter(
                            (obj) => obj.field !== e.target.name,
                        ),
                    );
                }
            });

            select.addEventListener('keyup', function (e) {
                setFilters(e);
                const filter_obj = { [e.target.name]: e.target.value };
                const event = new CustomEvent('filter_change', {
                    detail: filter_obj,
                });
                filtersElement.dispatchEvent(event);
            });
            select.addEventListener('paste', function (e) {
                setFilters(e);
                const filter_obj = { [e.target.name]: e.target.value };
                const event = new CustomEvent('filter_change', {
                    detail: filter_obj,
                });
                filtersElement.dispatchEvent(event);
            });
            select.addEventListener('change', function (e) {
                setFilters(e);
                const filter_obj = { [e.target.name]: e.target.value };
                const event = new CustomEvent('filter_change', {
                    detail: filter_obj,
                });
                filtersElement.dispatchEvent(event);
            });
        });
    }
    return table;
}

export function initializeTableFilter() {
    const filter_btn = document.querySelector('.filter-btn');

    filter_btn.addEventListener('click', (e) => {
        toggleAreaExpanded(filter_btn);
        filter_btn.classList.toggle('active');
    });
}

export default {
    initializeTable,
    initializeTableFilter,
};
