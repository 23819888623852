import { initializeTable } from '../tables';
import LinkButtonColumn from '../formatters/LinkButtonColumn';
import { deletePrompt } from '../../prompts';
import DeleteButtonColumn from '../formatters/DeleteButtonColumn';

export default async function initializeWasteCollectRequestsTable({
    ...options
}) {
    const rootElement = document.querySelector('#wasteCollectRequestsTable');

    const [statuses, types] = await Promise.all([
        fetch('/api/wastes/waste_collect_requests/statuses/').then(
            (response) => {
                return response.json();
            },
        ),
        fetch('/api/wastes/waste_collect_requests/types/').then((response) => {
            return response.json();
        }),
    ]);

    const statusFormatter = statuses.reduce(
        (obj, item) => Object.assign(obj, { [item.value]: item.label }),
        {},
    );

    const typesFormatter = types.reduce(
        (obj, item) => Object.assign(obj, { [item.value]: item.label }),
        {},
    );

    const wasteTable = initializeTable({
        tableElement: rootElement,
        ajaxURL: rootElement.dataset.ajaxUrl,
        layout: 'fitData',
        columns: [
            {
                title: gettext('#'),
                field: 'id',
                formatter: function (cell) {
                    const data = cell.getData();
                    if (data.notification) {
                        const status =
                            data.status == 4 || data.status == 5 ? 'red' : '';
                        return `${data.id} <span class="status-indicator ${status}"></span>`;
                    }
                    return data.id;
                },
                maxWidth: 100,
                resizable: false,
                cssClass: 'space-between',
            },
            {
                title: gettext('Status'),
                field: 'status',
                formatter: 'lookup',
                formatterParams: statusFormatter,
                resizable: false,
                widthGrow: 2,
            },
            {
                title: gettext('Typ'),
                field: 'request_type',
                formatter: 'lookup',
                formatterParams: typesFormatter,
                resizable: false,
            },
            {
                title: gettext('Data utworzenia'),
                field: 'creat_date',
                formatter: 'datetime',
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",
                    outputFormat: 'yyyy-MM-dd HH:mm',
                    invalidPlaceholder: gettext('Zły format daty'),
                    timezone: 'Poland',
                },
                resizable: false,
            },
            {
                title: gettext('Data modyfikacji'),
                field: 'change_date',
                formatter: 'datetime',
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",
                    outputFormat: 'yyyy-MM-dd HH:mm',
                    invalidPlaceholder: gettext('Zły format daty'),
                    timezone: 'Poland',
                },
                resizable: false,
            },
            {
                title: gettext('Data przekazania zgłoszenia'),
                field: 'request_date',
                formatter: 'datetime',
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",
                    outputFormat: 'yyyy-MM-dd HH:mm',
                    invalidPlaceholder: gettext('Zły format daty'),
                    timezone: 'Poland',
                },
                resizable: false,
            },
            {
                title: gettext('Akcje'),
                resizable: false,
                headerSort: false,
                maxWidth: 150,
                formatter: function (cell, formatterParams, onRendered) {
                    return `<div>${LinkButtonColumn(cell, formatterParams[0], onRendered)}
${DeleteButtonColumn(cell, formatterParams[1], onRendered)}</div>`;
                },
                formatterParams: [
                    {
                        url: function (cell) {
                            if (cell.getRow().getData().request_type === 1) {
                                return `/wastes/request/automatic/wastes/${cell.getRow().getData().id}/`;
                            } else {
                                return `/wastes/request/manual/${cell.getRow().getData().id}/`;
                            }
                        },
                        text: gettext('Edytuj'),
                        disabledCondition: function (cell) {
                            return 0 !== cell.getRow().getData().status;
                        },
                    },
                    {
                        url: function (cell) {
                            return `/api/wastes/waste_collect_requests/${cell.getRow().getData().id}/delete/`;
                        },
                        text: gettext('Usuń'),
                        disabledCondition: function (cell) {
                            return 0 !== cell.getRow().getData().status;
                        },
                    },
                ],
            },
        ],
        rowFormatter: async (row) => {
            const data = row.getData();
            if (data.notification) {
                const element = row.getElement();
                element.classList.add('tabulator-changed-row');
            }
        },
        ...options,
    });

    wasteTable.on('rowClick', function (e, row) {
        if (row.getData().status !== 0)
            window.location.href = `/wastes/request/detail/${row.getData().id}/`;
    });
}
