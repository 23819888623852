import '../sass/project.scss';
import { DateTime } from 'luxon';
import { initializeTable, initializeTableFilter } from './tables/tables';
import initializeUsersTable from './tables/definitions/users-table';
import initializeWasteCollectRequestsTable from './tables/definitions/waste-collect-requests-table';
import wastes from './waste_edit';
import modals from './modals';
import alerts from './alerts';
import utils from './utils';
import files from './file_upload';
import datetime from './datetime';

window.DateTime = DateTime;

window.tables = {
    initializeTable: initializeTable,
    initializeUsersTable: initializeUsersTable,
    initializeTableFilter: initializeTableFilter,
    initializeWasteCollectRequestsTable: initializeWasteCollectRequestsTable,
};

window.alerts = {
    initializeAlert: alerts.initializeAlert,
    addAlert: alerts.addAlert,
};

window.wastes = {
    initializeWastes: wastes.initializeWastes,
};

window.utils = {
    toggleAreaExpanded: utils.toggleAreaExpanded,
    hideOnClickOutside: utils.hideOnClickOutside,
    SelectInsteadTabs: utils.SelectInsteadTabs,
    getCookie: utils.getCookie,
    initializeNumberSpinner: utils.initializeNumberSpinner,
    operateOnInput: utils.operateOnInput,
    initializeSelect: utils.initializeSelect,
    initializeAddressAutoFill: utils.initializeAddressAutoFill,
    interceptError: utils.interceptError,
    isRequiredFulfilled: utils.isRequiredFulfilled,
    isRequiredFulfilledListener: utils.isRequiredFulfilledListener,
    checkChangeError: utils.checkChangeError,
    initializeAddressSave: utils.initializeAddressSave,
};

window.modals = {
    createModal: modals.createModal,
    initializeModalForm: modals.initializeModalForm,
    closeModal: modals.closeModal,
};

window.files = {
    initialize: files.initialize,
    initializeFileUpload: files.initializeFileUpload,
    uploadFile: files.uploadFile,
};

window.datetime = {
    initializeDateTimeFields: datetime.initializeDateTimeFields,
    intializeCustomDatepicker: datetime.initializeCustomDatepicker,
    initializeCustomTimepicker: datetime.initializeCustomTimepicker,
};

const loadedEvent = new Event('bundleLoaded');
document.dispatchEvent(loadedEvent);

utils.handleMobileMenu();
