import { initializeTable } from '../tables';
import toggleLink from '../formatters/toggleLink';
import RowContextMenuButtonColumn from '../formatters/RowContextMenuButtonColumn';
import { getCookie } from '../../utils';
import { addAlert } from '../../alerts';

const user_actions = {
    enableUrl: (cell_id) => `/api/users/${cell_id}/activate`,
    disableUrl: (cell_id) => `/api/users/${cell_id}/deactivate`,
};

const user_staff_actions = {
    enableUrl: (cell_id) => `/api/users/${cell_id}/add_group_staff`,
    disableUrl: (cell_id) => `/api/users/${cell_id}/remove_group_staff`,
};

const user_managers_actions = {
    enableUrl: (cell_id) => `/api/users/${cell_id}/add_group_managers`,
    disableUrl: (cell_id) => `/api/users/${cell_id}/remove_group_managers`,
};

const makeRequest = async function (endpoint) {
    return await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
    });
};

export default function initializeUsersTable({ ...options }) {
    const rootElement = document.querySelector('#usersTable');
    const table = initializeTable({
        tableElement: rootElement,
        filtersElement: document.querySelector('#usersFilters'),
        ajaxURL: rootElement.dataset.ajaxUrl,
        rowContextMenu: function (e, component) {
            const component_data = component.getData();
            let menu = [];
            const that = this;
            const block_item = {
                label: component_data.is_active
                    ? gettext('Zablokuj')
                    : gettext('Odblokuj'),
                action: function (e, row) {
                    const row_data = row.getData();
                    if (row_data.is_active) {
                        return makeRequest(
                            user_actions.disableUrl(row_data.id),
                        ).then((res) => {
                            component.update({ is_active: false });
                            that.setData();
                        });
                    } else {
                        return makeRequest(
                            user_actions.enableUrl(row_data.id),
                        ).then((res) => {
                            component.update({ is_active: true });
                            that.setData();
                        });
                    }
                },
            };
            menu.push(block_item);

            const staff_item = {
                label: component_data.groups.includes('Staff')
                    ? gettext('Zabierz uprawnienia Logistyka')
                    : gettext('Nadaj uprawnienia Logistyka'),
                action: function (e, row) {
                    const row_data = row.getData();
                    if (row_data.groups.includes('Staff')) {
                        return makeRequest(
                            user_staff_actions.disableUrl(row_data.id),
                        ).then(async (res) => {
                            if (res.ok) {
                                const old_group = row_data.groups
                                    .split(',')
                                    .filter((item) => item);
                                const index = old_group.indexOf('Staff');
                                old_group.splice(index, 1);
                                row.update({ groups: old_group });
                                that.setData();
                            } else {
                                const data = await res.json();
                                addAlert('error', data.message);
                            }
                        });
                    } else {
                        return makeRequest(
                            user_staff_actions.enableUrl(row_data.id),
                        ).then(async (res) => {
                            if (res.ok) {
                                const old_group = row_data.groups
                                    .split(',')
                                    .filter((item) => item);
                                old_group.push('Staff');
                                row.update({ groups: old_group });
                            } else {
                                const data = await res.json();
                                addAlert('error', data.message);
                            }
                        });
                    }
                },
            };
            menu.push(staff_item);

            const manager_item = {
                label: component_data.groups.includes('Managers')
                    ? gettext('Zabierz uprawnienia Managera')
                    : gettext('Nadaj uprawnienia Managera'),
                action: function (e, row) {
                    const row_data = row.getData();
                    if (row_data.groups.includes('Managers')) {
                        return makeRequest(
                            user_managers_actions.disableUrl(row_data.id),
                        ).then(async (res) => {
                            if (res.ok) {
                                const old_group = row_data.groups
                                    .split(',')
                                    .filter((item) => item);
                                const index = old_group.indexOf('Managers');
                                old_group.splice(index, 1);
                                row.update({ groups: old_group });
                                that.setData();
                            } else {
                                const data = await res.json();
                                addAlert('error', data.message);
                            }
                        });
                    } else {
                        return makeRequest(
                            user_managers_actions.enableUrl(row_data.id),
                        ).then(async (res) => {
                            if (res.ok) {
                                const old_group = row_data.groups
                                    .split(',')
                                    .filter((item) => item);
                                const index = old_group.indexOf('Managers');
                                old_group.splice(index, 1);
                                row.update({ groups: old_group });
                                that.setData();
                            } else {
                                const data = await res.json();
                                addAlert('error', data.message);
                            }
                        });
                    }
                },
            };
            menu.push(manager_item);

            return menu;
        },
        columns: [
            {
                title: gettext('Imię i nazwisko'),
                field: 'name',
                mutator: (value, data) =>
                    `${data.first_name} ${data.last_name}`,
                resizable: false,
            },
            {
                title: gettext('E-mail'),
                field: 'email',
                resizable: false,
            },
            {
                title: gettext('Numer telefonu'),
                field: 'phone',
                resizable: false,
            },
            {
                title: gettext('Numer NIP'),
                field: 'nip',
                resizable: false,
            },
            {
                title: gettext('Numer BDO'),
                field: 'bdo_number',
                resizable: false,
            },
            {
                title: gettext('Grupy'),
                field: 'groups',
                mutator: (value, data) => value.join(', '),
                resizable: false,
                headerSort: false,
            },
            {
                title: gettext('Akcja'),
                resizable: false,
                headerSort: false,
                width: 100,
                formatter: RowContextMenuButtonColumn,
            },
        ],
        ...options,
    });
    return table;
}
